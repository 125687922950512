import { Page } from "components/Page";
import { PageHeader } from "components/PageHeader";
import { PageTitle } from "components/PageTitle";
import React from "react";

export default function DJPage() {
  return (
    <Page>
      <PageHeader imageSrc="/hp-hero.jpg" />

      <div className="container lg:max-w-4xl mx-auto mt-32 ">
        <div className="flex flex-col md:flex-row-reverse gap-8">
          <img className="mx-auto w-full max-w-sm" src="/djislanddre.jpg" />
          <div>
            <h3>ISLAND DRE</h3>
            <p>
              Island Dre, is a multi-genre DJ, with a solid music knowledge
              across the board from Afrobeats to House. He is able to read a
              crowd well and understand what can get any audience going. Island
              Dre shows a strong passion for music and is able to express this
              through the avenue of DJing ​ Strongest Genres; Dancehall, Soca,
              UK/US HipHop, RnB.
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row mt-24 gap-8">
          <img className="mx-auto w-full max-w-sm" src="/djdre.jpg" />
          <div>
            <h3>DJ DRE</h3>
            <p>
              DJ Dre is an Internationally established Dj with over 5 years
              experience playing in Europe's biggest clubs and supporting some
              of the biggest artists such as Drake, Pop Smoke, D Block Europe +
              many more. He specialises in R'n'B & Hip Hop but is recognised as
              a multi-genre dj. Alongside Uncle Teo, Dre Has Not only
              established brands such as "Whats the 411" "Saucy" & "Mojito
              Monday" but together they are a dynamic duo that bring energy and
              vibes to any bar, club or festival they attend.
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row-reverse mt-24 gap-8">
          <img className="mx-auto w-full max-w-sm" src="/djsk.jpg" />
          <div className="flex-1">
            <h3>DJ SK</h3>
            <p>
              A crowd controller who always gets the ravers off their feet. An
              afrobeats specialist whilst still remaining solid in all other
              genre's. Not only is SK an accomplished DJ he can also make his
              presence known on the mic when DJing, book SK now for any party!
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-8 mt-24">
          <img className="mx-auto w-full max-w-sm" src="/djsherry.jpg" />
          <div>
            <h3>DJ SHERRY</h3>
            <p>
              Crowd control, Class and Charisma guaranteed if you see DJ Sherry
              on the line-up. Im know to disturb the public when my hands are on
              the decks. In under 3 years, and having played anything from house
              parties to sell out events all over the UK, the name DJ Sherry is
              one you’ve heard by now, and if not, you’ll be hearing it soon
              enough. Multi-genre and multi-talented, you have multiple reasons
              to book!
            </p>
          </div>
        </div>
      </div>
    </Page>
  );
}
